<template>
  <div class="w100 wtm_statistics_height bg-fff pl-20 pr-20 pt-20 pb-20 autobox">
    <div class="w100 dp-f fw-w">
      <!-- <div class="input_box w-250 mb-24">
        <div class="input_title">模板类型：</div>
        <el-select v-model="state.searchKey.templateType" class="input" size="large">
          <el-option v-for="item in state.optionsType" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div> -->
      <div class="input_box w-250 mb-24">
        <div class="input_title">使用部门：</div>
        <el-select v-model="state.searchKey.dept" class="input" size="large">
          <el-option v-for="item in state.options" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>
      <div class="input_box w-400 mb-24">
        <div class="input_title">选择日期：</div>
        <el-date-picker v-model="state.searchKey.dateTime" type="datetimerange" start-placeholder="开始日期"
          end-placeholder="结束日期" value-format="YYYY-MM-DD HH:mm:ss"/>
      </div>
      <oabutton width="80" height="40" title="搜索" CSStype="2" style="border-radius: 4px" class="ml-12"
        @buttonclick="getDataList"></oabutton>
    </div>
    <statistics class="mb-40" :datas="state.statisticsData" v-loading="countLoading"
      :element-loading-text="config.loading.text" :element-loading-spinner="config.loading.spinner"
      :element-loading-background="config.loading.background"></statistics>
    <el-table :data="state.Notice" class="mb-20 table" :header-cell-style="{
      background: 'var(--active-alpha)',
      color: config.table.color,
      fontWeight: config.table.fontWeight,
      fontSize: config.table.fontSize,
    }" row-key="id" v-loading="loading" :element-loading-text="config.loading.text"
      :element-loading-spinner="config.loading.spinner" :element-loading-background="config.loading.background">
      <el-table-column :align="'center'" prop="templateTitle" label="模板主题" />
      <el-table-column :align="'center'" prop="templateNumber" label="模板编号" />
      <el-table-column :align="'center'" label="模板状态">
        <template #default="scope">
          <div class="dp-fc Status" :class="'Status' + scope.row.templateStatus">
            <!-- <div class="line"></div>
                        <div class="w-6 h-6 icon mr-9"></div>
                        <div class="w-70 ta-l">开启中</div> -->
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column :align="'center'" prop="templateType" label="模板类型" /> -->
      <el-table-column :align="'center'" prop="deptName" label="使用部门" />
      <el-table-column :align="'center'" prop="usageTime" label="使用时间" />
      <el-table-column :align="'center'" prop="userName" label="使用人" />
      <!-- <el-table-column :align="'center'" prop="name2" label="完成使用时间" /> -->
      <el-table-column :align="'center'" label="是否发起文件">
        <template #default="scope">
          <div>
            {{ scope.row.isInitiate ? "是" : "否" }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="dp-f jc-c" @current-change="handleCurrentChange" background layout="prev, pager, next,slot"
      :current-page="currentPage" :total="totalPage">
    </el-pagination>
  </div>
</template>
<script setup>
import { reactive, ref, unref } from "vue";
import { getcontractStatus } from "@/utils/server/getcode.js";
import statistics from "../components/statistics.vue";
import { getdept, gettemplate } from "@/utils/server/selectdata";
import { httpToken } from "@/utils/request";
import { queryResourceTable } from "@/api/cancelToken.js";
import qs from "qs";
const loading = ref(false); //loading 显示
const countLoading = ref(false);
const currentPage = ref(1); //当前页数
const totalPage = ref(0); //总条数
const state = reactive({
  searchKey: {
    templateType: "", //模版类型
    dept: "", //使用部门
    dateTime: null, //日期
  }, //搜索绑定值
  options: [], //下拉数据
  optionsType: [],
  Notice: [{ name2: "", status: 1 }], //表格数据
  statisticsData: [
    { title: "全部模板", count: 0, icon: "img1.png" },
    { title: "开启中", count: 0, icon: "img6.png" },
    { title: "已停用", count: 0, icon: "img9.png" },
    { title: "使用次数", count: 2, icon: "img5.png" },
  ], //统计数据
});
getdept().then((res) => {
  state.options = res;
});
// gettemplate().then((res) => {
//   state.optionsType = res;
// });
const getCountData = (type) => {
  countLoading.value = true;
  httpToken({
    url: "admin/templateCon/templateAdd",
    method: "POST",
  })
    .then((res) => {
      state.statisticsData[0].count = res.data.count;
      state.statisticsData[1].count = res.data.enable;
      state.statisticsData[2].count = res.data.disable;
      state.statisticsData[3].count = res.data.usageCount;
      console.log(res);
      countLoading.value = false;
    })
    .catch((err) => {
      countLoading.value = false;
    });
};
getCountData();
//获取列表数据
const getDataList = (val = 1) => {
  state.Notice = [];
  currentPage.value = val;
  loading.value = true;
  queryResourceTable(
    "/admin/templateCon/userList",
    qs.stringify({
      page: val,
      // templateType: state.searchKey.templateType,
      dept: state.searchKey.dept?String(state.searchKey.dept):'',
      dateTime: JSON.stringify(state.searchKey.dateTime),
    })
  )
    .then((res) => {
      // 如果存在请求页码和返回数据时页面不同的情况 按返回页码重新请求
      if (val !== res.data.current) {
        getDataList(res.data.current);
        return;
      }
      totalPage.value = res.data.total;
      state.Notice = res.data.records;
      loading.value = false;
    })
    .catch((err) => {
      if (err.message != "stop") loading.value = false;
    });
};
const handleCurrentChange = (val) => {
  getDataList(val);
};
getDataList();
</script>
<style lang="scss" scoped >
// 重新定义最小高度
.wtm_height {
  min-height: calc(100vh - 148px - 46px);
}

::v-deep .Status {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}

::v-deep .Status1 {
  color: #ff4d4f;
}

.table {
  min-height: 350px;
}
</style>