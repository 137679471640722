<template>
    <tablebox>
        <choosebuttons @Emit="butemit" :datas="[{ title: '合同签署' },{ title: '印章使用' },{ title: '模板使用' },{ title: '实名认证' },]"></choosebuttons>
        <signing v-if="state.butIndex==0"></signing>
        <seal v-if="state.butIndex==1"></seal>
        <templateList v-if="state.butIndex==2"></templateList>
        <realname v-if="state.butIndex==3"></realname>
    </tablebox>
</template>
<script setup>
import { reactive,ref,unref } from 'vue'
import choosebuttons from "@/components/button/choose.vue"
import signing from "./lists/signing.vue"//合同签署
import seal from "./lists/seal.vue"//印章使用
import templateList from "./lists/template.vue"//模板使用
import realname from "./lists/realname.vue"//实名认证
const state = reactive({
    butIndex:0,//按钮选中下标
})
// 按钮返回
const butemit=((el)=>{
    state.butIndex=el
})
</script>
<style lang="scss" scoped >
@import "@/styles/general/element.scss";  //element 样式重置
@import "@/styles/general/element/table.scss"; //element 列表样式重置
@import "@/styles/general/element/pagination1.scss"; //element 分页样式重置
</style>