<template>
  <div class="w100 wtm_statistics_height bg-fff pl-20 pr-20 pt-20 pb-20 autobox">
    <div class="w100 dp-f fw-w">
      <div class="input_box w-250 mb-24">
        <div class="input_title">合同类型：</div>
        <el-select v-model="state.searchKey.contractType" class="input" size="large">
          <el-option v-for="item in state.optionsType" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>
      <div class="input_box w-250 mb-24">
        <div class="input_title">签署状态：</div>
        <el-select v-model="state.searchKey.contractStatus" class="input" size="large">
          <el-option v-for="item in state.optionsStatus" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>
      <div class="input_box w-450 mb-24">
        <div class="input_title">选择日期：</div>
        <el-date-picker v-model="state.searchKey.dateTime" type="datetimerange" start-placeholder="开始日期"
          end-placeholder="结束日期" value-format="YYYY-MM-DD HH:mm:ss"/>
      </div>
      <oabutton width='80' height='40' title="搜索" CSStype=2 style="border-radius:4px;" class="ml-12"
        @buttonclick="getDataList"></oabutton>
    </div>
    <statistics class="mb-40" :datas="state.statisticsData" v-loading="countLoading"
      :element-loading-text="config.loading.text" :element-loading-spinner="config.loading.spinner"
      :element-loading-background="config.loading.background"></statistics>
    <!-- <el-table-column :align="'center'" prop="contractName" label="合同标题" />
			<el-table-column :align="'center'" prop="contractType" label="合同类型" />
			<el-table-column :align="'center'" prop="initiatorStr" label="发起方" />
			<el-table-column :align="'center'" prop="sponsorStr" label="发起人" />
			<el-table-column :align="'center'" prop="showSignatory" label="签署方" width="180" /> -->
    <el-table :data="state.Notice" class="mb-20 table"
      :header-cell-style="{ background: 'var(--active-alpha)', color: config.table.color, fontWeight: config.table.fontWeight, fontSize: config.table.fontSize }"
      row-key="id" v-loading="loading" :element-loading-text="config.loading.text"
      :element-loading-spinner="config.loading.spinner">
      <el-table-column :align="'center'" prop="contractName" label="合同标题" />
      <el-table-column :align="'center'" prop="contractNumber" label="合同编号" />
      <el-table-column :align="'center'" label="签署状态">
        <template #default="scope">
          <div class="dp-fc contractStatus" :class="'contractStatus' + scope.row.contractStatus">
            <div class="line"></div>
            <div class="w-6 h-6 icon mr-9"></div>
            <div class="w-70 ta-l">{{ getcontractStatus(scope.row.contractStatus) }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :align="'center'" prop="contractTypeStr" label="文件类型" />
      <el-table-column :align="'center'" prop="sealName" label="印章名称" />
      <el-table-column :align="'center'" prop="sealCount" label="用印次数" />
      <el-table-column :align="'center'" prop="sealRecordUser" label="用印人" />
      <el-table-column :align="'center'" prop="initiatorStr" label="发起方" />
      <el-table-column :align="'center'" prop="companyName" label="签署方" />
      <el-table-column :align="'center'" prop="initiateData" label="发起时间" />
    </el-table>
    <el-pagination class="dp-f jc-c mb-20" @current-change="handleCurrentChange" background
			layout="prev, pager, next,slot" :current-page="currentPage" :total="totalPage">
		</el-pagination>
  </div>
</template>
<script setup>
import { onMounted, reactive, ref, unref } from 'vue'
import { getcontractStatus } from "@/utils/server/getcode.js"
import { getcontractData,getworkTypeData } from "@/utils/server/selectdata.js"
import statistics from "../components/statistics.vue"
import { httpToken } from "@/utils/request";
import { queryResourceTable } from "@/api/cancelToken.js"
import qs from "qs";
const loading = ref(false);//loading 显示
const countLoading = ref(false);//loading 显示
const currentPage = ref(1);//当前页数
const totalPage = ref(0);//总条数
const state = reactive({
  searchKey: {
    contractType: '',//合同类型
    contractStatus: '',//签署状态
    dateTime: null,//选择日期
  },//搜索绑定值
  optionsType: [],//下拉数据
  optionsStatus: getcontractData(3),//签署状态下拉数据
  Notice: [],//表格数据
  statisticsData: [
    { title: '全部合同', count: 0, icon: 'img1.png' },
    { title: '已完成', count: 0, icon: 'img2.png' },
    { title: '签署中', count: 0, icon: 'img3.png' },
    { title: '已过期', count: 0, icon: 'img4.png' },
  ],//统计数据
})
getworkTypeData(undefined,true).then(res=>{
  state.optionsType=res
})
// 获取统计数据
const getCountData = (type) => {
  countLoading.value = true
  httpToken({
    url: '/initiate-contract/contract/signCount',
    method: 'POST',
    data: qs.stringify({
      type: 4
    })
  }).then(res => {
    state.statisticsData[0].count = res.data.count
    state.statisticsData[1].count = res.data.accomplish
    state.statisticsData[2].count = res.data.notHaveAccomplish
    state.statisticsData[3].count = res.data.loseEfficacy
    countLoading.value = false;
  }).catch((err) => {
    countLoading.value = false;
  })
}
getCountData()
const getDataList = (val = 1) => {
  state.Notice = []
  currentPage.value = val;
  loading.value = true
  queryResourceTable('/initiate-contract/contract/signContractList', qs.stringify({
    page: val,
    contractType: state.searchKey.contractType,
    contractStatus: state.searchKey.contractStatus,
    dateTime: JSON.stringify(state.searchKey.dateTime)
  })).then((res) => {
    // 如果存在请求页码和返回数据时页面不同的情况 按返回页码重新请求
		if (val !== res.data.current) {
			getDataList(res.data.current)
			return
		}
    totalPage.value = res.data.total;
    // res.data.records.forEach(item => {
    //   item.showSignatory = ''
    //   item.contractSignRecords.forEach((item2, ind2) => {
    //     item.initiatorStr = item.initiatorStr ? item.initiatorStr : '/'
    //     item.showSignatory += ind2 == 0 ? '' : ','
    //     item.showSignatory += item2.signerType == 1 ? item2.contractSignPersonList[0].companyName : item2.contractSignPersonList[0].signerName
    //   })
    // });
    state.Notice = res.data.records
    loading.value = false;
  }).catch((err) => {
    if (err.message != 'stop')
      loading.value = false;
  })
}

// 分页改变方法
const handleCurrentChange = ((val) => {
	getDataList(val)
})
onMounted(()=>{
getDataList()
})
</script>
<style lang="scss" scoped >
// 重新定义最小高度
.wtm_height {
  min-height: calc(100vh - 148px - 46px);
}

::v-deep .contractStatus {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}

::v-deep .contractStatus1 {
  color: #FF4D4F;
}

.table {
  min-height: 350px;
}
</style>